emoji-picker {
  --num-columns: 14;
  --emoji-size: 2rem;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  --input-border-radius: 0;
  --border-size: 0px;
}

.emojicribe-container {
  display: flex;
}

.emojicribe {
  font-size: 3vh;
  user-select: none;
  cursor: pointer;
}

.emijicribe--icon {
  position: absolute;
    bottom: 8px;
    right: 8px;
    font-size: 24px;
    cursor: pointer;
}

/* @media (min-width: 576px) {
  emoji-picker {
    --num-columns: 8;
    --emoji-size: 2rem;
  }
} */

@media (max-width: 768px) {
  emoji-picker {
    --num-columns: 7;
  }
}

@media (min-width: 992px) {
  emoji-picker {
    --num-columns: 9;
  }

  .emojicribe {
    font-size: 5vh;
  }
  
}

@media (min-width: 1200px) {
  emoji-picker {
    --num-columns: 11;
  }
}

@media (min-width: 1600px) {
  emoji-picker {
    --num-columns: 14;
  }
}
