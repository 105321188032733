.full-height-100vh {
  height: 100vh;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 8px !important;
}

.center-content--vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.center-content--horizontal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonMargin {
  margin-right: 8px;
  margin-bottom: 8px;
}
