.left-column--responsive {
  height: 15%;
}

.right-column--responsive {
  height: 85%;
}

@media (min-width: 992px) {
  .left-column--responsive,
  .right-column--responsive {
    height: 100%;
  }
}
