.ant-carousel button {
  background-color: black !important;
}

.ant-carousel {
  width: 180px;
  height: 250px;
  padding-left: 10px;
  padding-right: 10px;
}

.carousel__page {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  font-size: 30px;
}

.carousel__emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.carousel__icon {
  color: black;
  font-size: 16px;
}

.ant-carousel .slick-dots-bottom {
  bottom: -24px;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: unset;
  font-size: unset;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: unset;
}
